<template>
  <div class="meetingInfo_container global_full_screen">
    <itemScroll ref="itemScroll">
      <div class="meetingInfo_main">
        <div class="meetingInfo_body">
          <div class="meetingInfo_header">
            <img
              :src="require('@/assets/images/icon/meeting_info.png')"
              alt=""
            />
            <span>会议信息</span>
          </div>

          <!-- <div class="meetingInfo_row " v-if="config.projects.length > 1">
              <div class="meetingInfo_key">会议项目:</div>
              <div class="meetingInfo_value global_text_input">
                  <el-select v-model="data.project_id" placeholder="请选择项目" @change="changeProject" >
                    <el-option v-for="(item,index) in config.projects" :key="index" :label="item.project" :value="item.id"></el-option>
                  </el-select>
                  <div class="global_container_mask" v-if="isDisabled()"></div>
              </div>
          </div> -->
          <div class="meetingInfo_row">
            <div class="meetingInfo_key">会议名称:</div>
            <div class="meetingInfo_value global_text_input">
              <el-input
                v-model="data.name"
                @input="inputCreateMeetingDataVal('name')"
                placeholder="请输入会议名称"
              ></el-input>
              <div class="global_container_mask" v-if="isDisabled()"></div>
            </div>
          </div>
          <div class="meetingInfo_row" v-if="config.periodList.length > 1">
            <div class="meetingInfo_key">会议期数:</div>
            <div class="meetingInfo_value global_text_input">
              <el-select
                @change="changePeriodList"
                v-model="data.period"
                placeholder="请选择会议期数"
              >
                <el-option
                  v-for="(item, index) in config.periodList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div class="global_container_mask" v-if="isDisabled()"></div>
            </div>
          </div>
          <div class="meetingInfo_row">
            <div class="meetingInfo_key">会议日期:</div>
            <div class="meetingInfo_value global_text_input">
              <el-date-picker
                @blur="inputCreateMeetingDataVal('date')"
                v-model="meetingTime.date"
                :editable="false"
                :value-format="`YYYY-MM-DD`"
                type="date"
                placeholder="请选择会议日期"
              ></el-date-picker>
              <div class="global_container_mask" v-if="isDisabled()"></div>
            </div>
          </div>
          <div class="meetingInfo_row">
            <div class="meetingInfo_key">开始时间:</div>
            <div class="meetingInfo_value global_text_input">
              <el-select
                v-model="meetingTime.startTime"
                @change="changeStartTime"
                placeholder="请选择开始时间"
              >
                <el-option
                  v-for="(item, index) in config.dateList.slice(0, -2)"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div class="global_container_mask" v-if="isDisabled()"></div>
            </div>
          </div>
          <div class="meetingInfo_row">
            <div class="meetingInfo_key">结束时间:</div>
            <div class="meetingInfo_value global_text_input">
              <el-select
                @change="inputCreateMeetingDataVal('endTime')"
                v-model="meetingTime.endTime"
                placeholder="请选择结束时间"
              >
                <el-option
                  v-for="(item, index) in config.dateList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div class="global_container_mask" v-if="isDisabled()"></div>
            </div>
          </div>
          <div class="meetingInfo_row" :class="{meetingInfo_row_mt8:!!coursewareTitleLen}">
            <div class="meetingInfo_key">会议讲题:</div>
            <div v-if=" !data.id && ((projectInfo.custom_courseware==1 && !config.periodList.length) || (data.meet_count>0))" class="meetingInfo_value global_text_input">
              <div @click="verifyPeriod" class="global_tip_text_input">请选择会议讲题</div>
            </div>
            <div v-else-if="projectInfo.custom_courseware==1" class="meetingInfo_value meetingInfo_value_courseware_block" :class="{'meetingInfo_value_courseware_block_border_none':!expertChooseText&&!courseware_data.courseware_id}">
              <!-- <el-select v-model="data.courseware_id" placeholder="请选择会议讲题" >
                    <el-option v-for="(item,index) in config.coursewares" :key="index" :label="item.title" :value="item.id"></el-option>
                  </el-select> -->

              <div v-if="expertChooseText=='待专家选择' || (data.id && !data.courseware_id)" class="global_text_input global_tip_text_input">
                <span class="">{{expertChooseText || '专家自己选'}}</span>
              </div>

              <template v-else-if="data.courseware_id">
                <div
                  class="meetingInfo_courseware"
                  @click="wantSelectCourseware"
                  :class="{ meetingInfo_courseware_select: data.courseware_id }"
                >
                  {{ getCoursewareTitle() || '请选择会议讲题'}}
                </div>
                <customButton @click="goToPreview"
                >课件预览</customButton
                >
              </template>
              <div class="meetingInfo_projectId_button" v-else>
                <customButton class="meetingInfo_button" size="small" @click="chooseLiterature('other')">
                  <div class="meetingInfo_button_info">
                    <span>我帮专家选</span>
                  </div>
                </customButton>
                <customButton class="meetingInfo_button" size="small" @click="chooseLiterature('me')">
                  <div class="meetingInfo_button_info">
                    <span>专家自己选</span>
                  </div>
                </customButton>
              </div>
              <div class="global_container_mask" v-if="isDisabled()"></div>
            </div>
            <div v-else-if="+projectInfo.custom_courseware===0" class="meetingInfo_value global_text_input">
              <div
                class="meetingInfo_courseware"
                @click="wantSelectCourseware"
                :class="{ meetingInfo_courseware_select: data.courseware_id }"
              >
                {{ getCoursewareTitle() || '请选择会议讲题'}}
              </div>
              <customButton v-if="data.courseware_id" @click="goToPreview">课件预览</customButton>
              <div class="global_container_mask" v-if="isDisabled()"></div>
            </div>
          </div>
        </div>
        <div class="meetingInfo_body">
          <div class="meetingInfo_header">
            <img
              :src="require('@/assets/images/icon/meeting_info.png')"
              alt=""
            />
            <span>主持专家信息</span>
          </div>
          <p class="doctor_resumeInfo_tips" v-if="isHaveDoctorPage()">
            注：如需修改请至上页专家个人信息
          </p>
          <div class="meetingInfo_row">
            <div class="doctor_resumeInfo_key">姓名:</div>
            <div class="meetingInfo_value global_text_input">
              <span>{{ doctorInfo.name }}</span>
            </div>
          </div>
          <div class="meetingInfo_row">
            <div class="doctor_resumeInfo_key">省份:</div>
            <div class="meetingInfo_value global_text_input">
              <span>{{ doctorInfo.province }}</span>
            </div>
          </div>
          <div class="meetingInfo_row">
            <div class="doctor_resumeInfo_key">城市:</div>
            <div class="meetingInfo_value global_text_input">
              <span>{{ doctorInfo.city }}</span>
            </div>
          </div>
          <div class="meetingInfo_row">
            <div class="doctor_resumeInfo_key">医院:</div>
            <div class="meetingInfo_value global_text_input">
              <span>{{ doctorInfo.hospital }}</span>
            </div>
          </div>
          <div class="meetingInfo_row" v-if="isHaveDoctorPage()">
            <div class="doctor_resumeInfo_key">专家简历职称:</div>
            <div class="meetingInfo_value resume_text_input">
              <span>{{ doctorInfo.resume || "暂无" }}</span>
            </div>
          </div>

          <div class="meetingInfo_row" v-if="isHaveDoctorPage()">
            <div class="doctor_resumeInfo_key">简历行政职务:</div>
            <div class="meetingInfo_value resume_text_input">
              <span>{{ doctorInfo.job || "暂无" }}</span>
            </div>
          </div>

          <div class="meetingInfo_row" v-if="isHaveDoctorPage()">
            <div class="doctor_resumeInfo_key">简历学术任职<br />及其他:</div>
            <div class="meetingInfo_value resume_text_input">
              <span>{{
                doctorInfo.resume_academic
                  ? doctorInfo.resume_academic.replace(/[,\n\s]/g, "\n")
                  : "暂无"
              }}</span>
            </div>
          </div>

          <div class="meetingInfo_row" v-if="isHaveDoctorPage()">
            <div class="doctor_resumeInfo_key">专家简历照片:</div>
            <div>
              <div
                v-if="doctorInfo.profile"
                class="meetingInfo_value_doctorInfo_profile"
              >
                <img :src="doctorInfo.profile" />
              </div>
              <span v-else>暂无</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="meetingInfo_row meetingInfo_button_group"
        v-if="!isDisabled()"
      >
        <customButton class="meetingInfo_button" size="small" @click="doCommit">
          <div class="meetingInfo_button_info">
            <img :src="require('@/assets/images/icon/apply.png')" alt="" />
            <span>{{
              this.$route.query.meetId ? "确认修改" : "确认申请"
            }}</span>
          </div>
        </customButton>
      </div>
    </itemScroll>
    <popup :cfg="expertChoosePopupConfig" v-if="showExpertChoosePopup">
      <div class="doctorMeets_tip_content">
        <div class="doctorMeets_tip_content_text">
          确认由专家自行选择吗？
        </div>
        <div class="doctorMeets_add_page">
          <customButton class="doctorMeets_help_button" @click="expertChooseClosePopup()">
            取消
          </customButton>
          <customButton class="doctorMeets_help_button"  @click="expertChooseSurePopup()">
            确认
          </customButton>
        </div>
      </div>
    </popup>
    <opacityBg
      v-if="showSelectCourseware"
      @click="this.showSelectCourseware = false"
    >
      <div
        class="meetingInfo_course_select_container_center"
        v-if="config.coursewares.length > 0 && config.coursewares.length <= 4"
      >
        <div class="meetingInfo_course_select" @click.stop="">
          <div
            class="meetingInfo_course_select_title global_primary_text_color"
          >
            会议讲题
          </div>
          <div
            class="meetingInfo_course_select_ul"
            v-for="(item, index) in config.coursewares"
            :key="index"
          >
            <el-radio
              class="meetingInfo_course_select_radio"
              @change="changeCourseware"
              v-model="data.courseware_id"
              :label="item.id"
              >&nbsp;</el-radio
            >
            <div
              class="meetingInfo_course_select_radio_label"
              @click="doSelectCourseware(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="meetingInfo_course_select_container"
        v-if="config.coursewares.length > 4"
      >
        <itemScroll ref="selectScroll">
          <div class="meetingInfo_course_select" @click.stop="">
            <div
              class="meetingInfo_course_select_title global_primary_text_color"
            >
              会议讲题
            </div>
            <div
              class="meetingInfo_course_select_ul"
              v-for="(item, index) in config.coursewares"
              :key="index"
            >
              <el-radio
                class="meetingInfo_course_select_radio"
                @change="changeCourseware"
                v-model="data.courseware_id"
                :label="item.id"
                >&nbsp;</el-radio
              >
              <div
                class="meetingInfo_course_select_radio_label"
                @click="doSelectCourseware(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </itemScroll>
      </div>
    </opacityBg>

  </div>
</template>
<script>
import {
  ElSelect,
  ElDatePicker,
  ElOption,
  ElRadio,
  ElInput,
} from "element-plus";
import customButton from "@/components/unit/customButton";
import itemScroll from "@/components/unit/itemScroll";
import opacityBg from "@/components/unit/opacityBg";
import popup from "@/components/unit/popup";
export default {
  name: "meetingInfo",
  components: {
    itemScroll: itemScroll,
    opacityBg: opacityBg,
    customButton: customButton,
    ElDatePicker,
    ElSelect,
    ElOption,
    ElRadio,
    ElInput,
    popup,
  },
  data() {
    return {
      periodData: {},
      courseware_data: {
        courseware_id:"",
        courseware_title:""
      },
      showExpertChoosePopup: false,
      expertChooseText: null,
      expertChoosePopupConfig: {
        title:'',
        content:"",
        button:[]
      },
      configProjectId: this.$config.projectId,
      config: {
        periodList: [],
        dateList: [],
        coursewares: [],
        projects: [],
      },
      data: {
        doctor_id: "",
        project_id: "",
        name: "",
        start_time: "",
        end_time: "",
        courseware_id: 0,
        article_id: "",
      },
      meetingTime: {
        date: "",
        startTime: "",
        endTime: "",
      },
      checkTime: 0,
      projectInfo: {
        custom_courseware: ""//0老模式(判断课件有么有上传data.courseware_id是否存在) 1新模式
      },
      doctorInfo: {},
      loading: false,
      showSelectCourseware: false,
    };
  },
  computed: {
    coursewareTitleLen: function (){
      return this.getCoursewareTitle().length >= 20
    }
  },
  watch: {
    'meetingTime.endTime': function (val) {
      if(!val) return;
      this.inputCreateMeetingDataVal('endTime')
    }
  },
  created() {
    // this.removeCurDataTemporaryStorage()
    this.data.doctor_id = this.$route.query.id;
    this.initDateList();
    this.loadDoctorInfo();
    if (this.$route.query.meetId) {
      document.getElementsByTagName("title")[0].innerHTML = "修改会议";
      this.loadData();
    } else {
      document.getElementsByTagName("title")[0].innerHTML = "新建会议";
      let coursewareItem = this.$tools.getStorage(
        "coursewareItem",
        sessionStorage
      );
      if (coursewareItem) {
        this.data = {
          ...this.data,
          ...coursewareItem.data,
        };
        this.meetingTime = {
          ...this.meetingTime,
          ...coursewareItem.meetingTime,
        };
      }
      this.$tools.setStorage("coursewareItem", "", sessionStorage);
      this.loadProjectInfo();
    }
    const createMeetingData = this.$tools.getStorage('createMeetingData',sessionStorage)
    if(createMeetingData) {
      this.data.name = createMeetingData.name;
      this.data.period = createMeetingData.period;
      this.meetingTime.date = createMeetingData.date;
      this.meetingTime.startTime = createMeetingData.startTime;
      this.meetingTime.endTime = createMeetingData.endTime;
    }
    const coursewareInfo = this.$tools.getStorage('coursewareInfo',sessionStorage)
    if(coursewareInfo) {
      this.courseware_data = coursewareInfo;
      this.data.article_id = this.courseware_data.courseware_id
      this.data.courseware_id = this.courseware_data.the_right_courseware_id
    }
  },
  mounted() {
    // this.loadProjectInfo()
    this.$nextTick(() => {
      this.$refs.itemScroll.initScroll();
    });
    setTimeout(() => {
      this.$refs.itemScroll.initScroll();
    }, 3000);
  },
  methods: {
    inputCreateMeetingDataVal(key){
      if(!this.config.periodList.length) return;
      const createMeetingData = this.$tools.getStorage('createMeetingData',sessionStorage)
      let data = {
        ...createMeetingData
      }
      data[key] = key === 'startTime' || key === 'endTime' || key === 'date' ? this.meetingTime[key] : this.data[key]
      this.$tools.setStorage("createMeetingData", data, sessionStorage)
    },
    changePeriodList(val){
      this.inputCreateMeetingDataVal('period')
      this.data.courseware_id = 0
      this.$tools.removeStorage('coursewareInfo',sessionStorage)
      this.periodData = this.config.periodList.find(item=>item.value === val)
    },
    removeCurDataTemporaryStorage(){
      this.$tools.removeStorage('createMeetingData',sessionStorage)
      this.$tools.removeStorage('coursewareInfo',sessionStorage)
    },
    loadPeriodList(){
      const data = {
        doctor_id: process.env.VUE_APP_doctorId || this.data.doctor_id,
        project_id: this.$tools.getStorage("projectId", sessionStorage)
      }
      let url = this.$tools.getURL(this.$urls.role.doctor_period,data)
      this.$axios.get(url).then(res => {
        this.config.periodList = this.formatPeriodStrList(res.data);
        if(res.data.length === 1) {
          this.data.period = this.config.periodList[0].value
          this.data.meet_count = res.data[0].meet_count
          this.periodData = this.config.periodList.find(item=>item.value === this.data.period)
          console.log(`this.periodData===>`, this.periodData)
        }
      })
    },
    chooseLiterature(type){
      if(type === 'other') {
        if(!this.data.period && this.config.periodList.length > 1) {
          this.$tips.error({text:'请选择会议期数'})
          return;
        }
        const data = {
          name: this.data.name,
          period: this.data.period,
          date: this.meetingTime.date,
          startTime: this.meetingTime.startTime,
          endTime: this.meetingTime.endTime,
        }
        this.$tools.setStorage("createMeetingData", data, sessionStorage)
        const iHelpHim = 'iHelpHim' // 我帮专家选
        this.$router.push(`/literatureLimit?period=${this.data.period}&t=${iHelpHim}&doctor_id=${this.data.doctor_id}`)
        return;
      }
      this.showExpertChoosePopup = true;
    },
    expertChooseSurePopup(){
      this.showExpertChoosePopup = false;
      this.data.courseware_id = ""
      this.expertChooseText = '待专家选择'
    },
    expertChooseClosePopup(){
      this.showExpertChoosePopup = false;
    },
    formatPeriodStrList(arr){
      let numbs = [];
      arr.forEach(item => {
        if(!item.period.length || item.period.length <= 1) {
          item.period = `2022-${item.period}`
        }
        numbs.push(item)
      })
      return numbs.map((num) => {
        let year = num.period.split("-")[0];
        let month = parseInt(num.period.split("-")[1]);
        return {
          value: year === '2022' ? month : `${year}-${month}`,
          label: `${year}第${this.$tools.intToChinese(month)}期`,
          custom_courseware: num.custom_courseware,
          meet_count: num.meet_count,
          project_id: num.project_id,
          use: num.use,
        };
      });
    },
    initDateList() {
      let hour = 6,
        minute = 30,
        dateList = [];
      for (; hour < 24; hour++) {
        do {
          let time = `${this.$tools.getTimeText(
            hour
          )}:${this.$tools.getTimeText(minute)}`;
          dateList.push({
            label: time,
            value: `${time}:00`,
            count: hour * 60 + minute,
          });
          minute += 15;
        } while (minute < 60);
        minute = 0;
      }
      this.config.dateList = dateList;
    },
    initDate() {
      let [date, startTime] = this.data.start_time.split(" ");
      let endTime = this.data.end_time.split(" ")[1];
      this.meetingTime = {
        date,
        startTime: startTime.substr(0, 5),
        endTime: endTime.substr(0, 5),
      };
      console.log(this.meetingTime);
    },
    loadData() {
      let url = this.$tools.getURL(this.$urls.admin.meetDetail, {
        id: this.$route.query.meetId,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          console.log(this.data);
          this.initDate();
          this.loadProjectInfo(true);
        })
        .catch((err) => {});
    },
    loadProject(status = false) {
      let url = this.$tools.getURL(this.$urls.api.project);
      this.$axios
        .get(url)
        .then((res) => {
          this.config.projects = [...res.data].reverse();
          // if(!status) this.data.project_id = res.data[0].id;
          this.$nextTick(() => {
            this.$refs.itemScroll.initScroll();
          });
          if (this.data.project_id) this.loadCourseware();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadCourseware() {
      let meet_id = this.$route.query.meetId || 0;
      let url = this.$tools.getURL(this.$urls.api.courseware, {
        id: this.data.project_id,
        doctor_id: this.data.doctor_id,
        meet_id: meet_id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.coursewares = res.data;
          if (this.config.coursewares.length > 4) {
            this.$refs.selectScroll && this.$refs.selectScroll.initScroll();
          }
          // this.config.coursewares = [
          //   {
          //     title:"Pembrolizumab与化疗作为晚期食管癌二线治疗对KEYNOTE-181健康相关生活质量的影响。",
          //     id:1

          //   },
          //   {
          //     title:"Toripalimab加化疗作为先前EGFR-TKI治疗EGFR突变晚期NSCLC患者的二线治疗：多中心II期试验。",
          //     id:2

          //   },
          //   {
          //     title:"CCTG BR34:durvalumab和tremelimumab/-铂类化疗治疗转移性非小细胞肺癌的随机II期临床试验。",
          //     id:3

          //   }
          // ]
          this.$nextTick(() => {
            this.$refs.itemScroll.initScroll();
          });
        })
        .catch((err) => {});
    },
    loadProjectInfo() {
      let url = this.$tools.getURL(this.$urls.admin.project, {
        project_id: this.$tools.getStorage("projectId", sessionStorage),
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.projectInfo = res.data;
          this.$tools.setStorage("is_courseware", res.data.is_courseware, sessionStorage);
          this.data.project_id = this.projectInfo.id
          this.loadCourseware();
          if(+this.projectInfo.custom_courseware===1) {
            this.loadPeriodList()
          }
        })
        .catch((err) => {});
    },
    loadDoctorInfo() {
      let url = this.$tools.getURL(
        this.$urls.admin.doctorInfo,
        this.$route.query
      );
      this.$axios.get(url).then((res) => {
        this.doctorInfo = res.data;
      });
    },
    isHaveDoctorPage() {
      return !!this.projectInfo.doctor_page;
    },
    changeCourseware() {
      this.$nextTick(() => {
        this.$refs.itemScroll.initScroll();
      });
      this.showSelectCourseware = false;
    },
    wantSelectCourseware() {
      if (this.config.coursewares.length == 0) {
        this.$tips.error({ text: "暂无会议讲题" });
        return;
      }
      if(this.projectInfo.custom_courseware) return;
      this.showSelectCourseware = true;
      this.$refs.selectScroll && this.$refs.selectScroll.initScroll();
    },
    doSelectCourseware(item) {
      this.data.courseware_id = item.id;
      this.inputCreateMeetingDataVal('courseware_id')
      this.showSelectCourseware = false;
      this.$nextTick(() => {
        this.$refs.itemScroll.initScroll();
      });
    },
    getCoursewareTitle() {
      if (!this.data.courseware_id) return "";
      if(this.courseware_data.courseware_id){
        return this.courseware_data.courseware_title
      }
      let item = this.config.coursewares.find(
        (x) => x.id == this.data.courseware_id
      );
      return item ? item.title : "";
    },
    getEndTime() {
      let index = this.config.dateList
        .map((val) => val.value)
        .indexOf(this.meetingTime.startTime);
      this.meetingTime.endTime = this.config.dateList[index + 2].value;
    },
    changeStartTime() {
      this.inputCreateMeetingDataVal('startTime')
      this.getEndTime();
    },
    changeProject() {
      this.data.courseware_id = "";
      this.loadCourseware();
    },
    isDisabled() {
      return !this.$store.state.common.adminInfo.phone;
    },
    verifyPeriod(){
      //只有是新版，meet_count=0并且课件列表大于1才能新建会议
      this.$tips.error({ text: "您没有可选择的会议讲题" });
    },
    verifyData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入会议名称" });
        return false;
      }
      if (!this.meetingTime.date) {
        this.$tips.error({ text: "请选择会议日期" });
        return false;
      }
      if (!this.meetingTime.date) {
        this.$tips.error({ text: "请选择会议日期" });
        return false;
      }
      if (
        this.$tools.getTime(this.meetingTime.date) <
        this.$tools.getTime(this.$tools.getDate())
      ) {
        this.$tips.error({ text: "会议日期需在今天之后" });
        return false;
      }
      if (!this.meetingTime.startTime) {
        this.$tips.error({ text: "请选择开始时间" });
        return false;
      }
      if (!this.meetingTime.endTime) {
        this.$tips.error({ text: "请选择结束时间" });
        return false;
      }
      this.data.start_time = `${this.meetingTime.date} ${this.meetingTime.startTime}`;
      this.data.end_time = `${this.meetingTime.date} ${this.meetingTime.endTime}`;
      if (
        this.$tools.getTime(this.data.start_time) <
        Date.now() + 30 * 60 * 1000
      ) {
        this.$tips.error({ text: "会议开始时间必须在半小时以后" });
        return false;
      }
      if (
        this.$tools.getTime(this.data.start_time) >
        this.$tools.getTime(this.data.end_time)
      ) {
        this.$tips.error({ text: "会议开始时间不能大于会议结束时间" });
        return false;
      }
      if(this.projectInfo.custom_courseware && !this.data.id){
        if(this.config.periodList.length == 0){
          this.$tips.error({ text: "请选择会议讲题" });
          return
        }
        if(this.data.meet_count >= 1){
          this.$tips.error({ text: "您没有会议可以创建" });
          return
        }
      }
      if(+this.projectInfo.custom_courseware===0 && !this.data.courseware_id) {
        this.$tips.error({ text: "请选择会议讲题" });
        return
      }
      return true;
    },
    goToPreview() {
      if(this.courseware_data.courseware_id){
        this.goToPreviewByLiteratureLimitBack()
        return;
      }
      this.$tools.setStorage(
        "coursewareItem",
        {
          data: this.data,
          meetingTime: this.meetingTime,
        },
        sessionStorage
      );
      this.$router.push({
        path: "/coursePreview",
        query: {
          id: this.data.courseware_id,
        },
      });
    },
    goToPreviewByLiteratureLimitBack() {
      this.$tools.setStorage(
        "coursewareItem",
        {
          data: {
            ...this.data,
            courseware_id: this.courseware_data.courseware_id
          },
          meetingTime: this.meetingTime,
        },
        sessionStorage
      );
      this.$router.push({
        path: "/coursePreview",
        query: {
          id: this.courseware_data.the_right_courseware_id,
        },
      });
    },
    doCommit() {
      if (this.checkTime + 1000 > Date.now()) return;
      this.checkTime = Date.now();
      if (this.isDisabled() || this.loading || !this.verifyData()) return;
      this.loading = true;
      let key = this.data.id ? "patch" : "post";
      let urlPath = +this.projectInfo.custom_courseware===1?'newMeet':'meet'
      this.$tools.setStorage("createMeetingData", this.data, sessionStorage)
      this.$axios[key](this.$urls.admin[urlPath], this.data)
        .then(() => {
          let text = `${this.$route.query.meetId ? "修改" : "申请"}会议成功`;
          this.$tips.success({ text });
          this.removeCurDataTemporaryStorage();
          this.$router.push({
            path: "doctorMeets",
            query: { id: this.$route.query.id },
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
  },
};
</script>
<style>
.meetingInfo_container {
  padding: 4vw;
}
.meetingInfo_row {
  display: flex;
  align-items: center;
  margin-top: 2vw;
}
.meetingInfo_projectId_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.meetingInfo_projectId_button .meetingInfo_button {
  width: 32vw;
  margin-left: 5vw;
}
.meetingInfo_row .global_text_input {
  border: none;
}
.meetingInfo_value {
  border-bottom: 1px solid #e6e6e6 !important;
  padding: 0 1vw;
  flex: 1 0 0;
  position: relative;
}
.meetingInfo_value .el-input__icon {
  display: none;
}
.meetingInfo_value .el-input__inner {
  padding-left: 1vw;
}
.meetingInfo_key {
  white-space: nowrap;
  /* margin-right:4vw;
  width: 13vw;
  word-break: break-all; */
}
.doctor_resumeInfo_key {
  margin-right: 4vw;
  width: 13vw;
  word-break: break-all;
}
.meetingInfo_header img {
  height: auto;
  width: 4vw;
  margin-right: 2vw;
}
.meetingInfo_body:nth-child(2) {
  margin-top: 8vw;
}
.meetingInfo_button_group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8vw;
}
.meetingInfo_button {
  width: 74.66vw;
  height: 9.4vw;
}
.meetingInfo_button_info {
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.meetingInfo_button_info img {
  height: 5.1vw;
  width: auto;
  margin-right: 1vw;
}
.meetingInfo_course_select_container {
  height: 80%;
  width: 80%;
  overflow: hidden;
}
.meetingInfo_course_select_container_center {
  width: 80%;
}
.meetingInfo_course_select_title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.meetingInfo_course_select {
  background: rgba(224, 224, 224, 0.9);
  width: 100%;
  padding: 2vw 4vw;
  border-radius: 2vw;
}
.meetingInfo_course_select_ul {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 2vw;
}
.meetingInfo_course_select_ul:last-child {
  border: none;
}
.meetingInfo_course_select_ul .el-radio {
  margin-right: 0;
}
.meetingInfo_course_select_ul .el-radio__label {
  padding: 0;
  width: 0;
}
.meetingInfo_course_select_radio_label {
  word-break: break-all;
  margin-left: 2vw;
}

.meetingInfo_courseware {
  color: #c0c4cc;
  padding: 1vw 1vw;
  flex: 1 0 0;
  margin-right: 2vw;
  word-break: break-all;
}
.meetingInfo_courseware_select {
  color: #000;
}
.meetingInfo_value_courseware_block {
  display: flex;
  align-items: center;
}
.resume_text_input {
  min-height: 8.8vw;
  word-break: break-all;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}
.meetingInfo_value_doctorInfo_profile {
  width: 200px;
  height: 200px;
}
.meetingInfo_value_doctorInfo_profile img {
  width: 100%;
  height: 100%;
}
.doctor_resumeInfo_tips {
  margin-top: 20px;
  color: red;
  width: 100%;
  font-size: 3.5vw;
  display: flex;
  align-items: top;
  justify-content: flex-start;
}
.meetingInfo_value.meetingInfo_value_courseware_block_border_none {
  border-bottom: none !important;
}

.doctorMeets_tip_content{
  padding:4vw 0;
}
.doctorMeets_tip_content_text{
  display: flex;
  align-items: center;
  height: 16vw;;
  padding: 0 5vw;
}
.doctorMeets_add_page{
  display: flex;
  justify-content: center;
}
.doctorMeets_add_page .doctorMeets_help_button{
  flex: 30% 0 0;
  padding: 2vw 0;
}
.doctorMeets_add_page .doctorMeets_help_button:first-child{
  margin-right:5vw;
}
.global_tip_text_input {
  padding: 1vw 1vw;
  color: #c0c4cc;
}
.meetingInfo_row_mt8 {
  margin-top: 8vw;
}
</style>
